"use strict";

(function($) {
	console.log("😁");

	// Handle header menu items.
	$(document).on("mouseenter mouseleave", "#menu-header .menu-item-has-children", function(e) {
		if(e.type === "mouseenter") {
			$(".fl-page-content").addClass("fl-page-content--blur");
		} else {
			$(".fl-page-content").removeClass("fl-page-content--blur");
		}
	});

	// Handle header menu (open/close).
	$(document).on("click", ".fl-menu-mobile-toggle", function() {
		if($(this).hasClass("fl-active")) {
			$(this).find(".fl-menu-mobile-toggle-label").text("Close");
		} else {
			$(this).find(".fl-menu-mobile-toggle-label").text("Menu");
		}
	});

	// Handle resize.
	$(window).on("resize load", function(e) {
		if($(window).width() > 960) {
			if($(".fl-menu-mobile-toggle").find(".fl-menu-mobile-toggle-label").text() === "Close") {
				$(".fl-menu-mobile-toggle").find(".fl-menu-mobile-toggle-label").text("Menu");
			}
		}
	});
})(jQuery);
